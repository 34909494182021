import './App.css';
import Popup from './components/Popup';
import Index from './layouts/index';

function App() {
  return (
    <div className="App">
      <Popup/>
      <Index />
    </div>
  );
}

export default App;
