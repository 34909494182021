import React, { useState, useEffect } from 'react';
import './../assets/css/popup.css';

const Popup = () => {
  const [isOpen, setIsOpen] = useState(true);

  const closePopup = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      // Disable scrolling on the background
      document.body.classList.add('no-scroll');
    } else {
      // Enable scrolling when the popup is closed
      document.body.classList.remove('no-scroll');
    }

    // Cleanup function to remove the no-scroll class in case the component unmounts
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className="popup-overlay">
          <div className="popup-container">
            <div className="popup-header">
              <h2>Welcome to Our E-Learning Platform!</h2>
              <button className="popup-close" onClick={closePopup}>
                &times;
              </button>
            </div>
            <div className='middleContent'>
              <p className='heading-middle'>
                We are excited to introduce our new e-learning platform, where you can sign up for IELTS, PTE, and other online classes to enhance your skills from the comfort of your home.
              </p>
              <form className="popup-form">
                <a className="btn btn-warning buttonclass" target='_blank' rel='noopener noreferrer' href='http://elearning.whiteshadevisaconsultancy.com'> Register now </a>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
